<template>
  <v-container id="ticket" fluid tag="section">
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>Ticket Edit Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-data-table
      :headers="columns"
      :items="tickets"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
      :item-class="itemStatusColor"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <!-- <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template> -->
      <template v-slot:[`item.user_name`]="{ item }">
        {{ item.user_name.length > 0 ? item.user_name[0].first_name : "" }}
        {{ item.user_name.length > 0 ? item.user_name[0].last_name : "" }}
      </template>
      <template v-slot:[`item.ticket_status`]="{ item }">
        <v-select
          :items="status"
          v-model="item.ticket_status"
          @change="updateStatus(item._id, item.ticket_status)"
        ></v-select>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Update"
          :to="{
            name: 'Edit Ticket',
            params: { id: item._id },
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ format_date(item.created_at) }}
      </template>
      <template v-slot:[`item.closed_at`]="{ item }">
        {{ format_date(item.closed_at) }}
      </template>
    </v-data-table>
    <v-dialog v-model="dialogUpdate" max-width="500px">
      <v-card>
        <v-card-title>Are you sure you want to update record?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" depressed @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary darken-1" depressed @click="deleteItemConfirm"
            >Update</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { commonService } from "../../services/common.service";
import moment from "moment";

export default {
  props: ["edit"],
  data() {
    return {
      moduleName: "Tickets",
      modulePermission: [],
      search: null,
      page: 1,
      tickets: [],
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      loading: true,
      numbers: [],
      editFlash: false,
      status: ["Open", "Inprocess", "Close/Resolved"],
      ticket_status: null,
      dialogUpdate: false,
      updateTicketId: "",
      updateTicketStatus: "",
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "30px",
        },
        {
          sortable: false,
          text: "#Ticket No",
          value: "id",
          width: "40px",
          sortable: false,
        },
        {
          sortable: false,
          text: "Order No",
          value: "order_id",
          width: "40px",
          sortable: false,
        },
        {
          sortable: false,
          text: "User Name",
          value: "user_name",
          width: "170px",
        },
        {
          sortable: false,
          text: "Subject",
          value: "subject_line",
          width: "170px",
        },
        // {
        //   sortable: false,
        //   text: "Description",
        //   value: "description",
        //   width: "170px",
        // },
        {
          sortable: false,
          text: "Status",
          value: "ticket_status",
          width: "170px",
        },
        {
          sortable: false,
          text: "Created On",
          value: "created_at",
          width: "170px",
        },
        {
          sortable: false,
          text: "Closed on",
          value: "closed_at",
          width: "170px",
        },
      ];
    },
  },
  methods: {
    format_date(value) {
      if (value) {
        var res = new Date(value);
        return moment(res).format("DD/MM/YYYY h:m:s a");
      }
    },
    getTickets() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "listingTicket/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.tickets = response.data.getUserTickets.data;
            this.loading = false;
            this.totalPages = response.data.getUserTickets.last_page;
            this.startRecord = response.data.getUserTickets.from;
            this.endRecord = response.data.getUserTickets.to;
            this.totalRecord = response.data.getUserTickets.total;
            this.numbers = [];
            for (
              let num = response.data.getUserTickets.from;
              num <= response.data.getUserTickets.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    closeDelete() {
      this.dialogUpdate = false;
      this.getTickets();
    },
    updateStatus(ticketId, status) {
      this.dialogUpdate = true;
      this.updateTicketId = ticketId;
      this.updateTicketStatus = status;
    },
    deleteItemConfirm() {
      let data = {
        id: this.updateTicketId,
        ticket_status: this.updateTicketStatus,
      };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "ticket/update", data)
        .then((response) => {
          if (response.status == 200) {
            this.dialogUpdate = false;
            this.editFlash = true;
            this.getTickets();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getTickets();
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getTickets();
    },
    itemStatusColor: function (item) {
      if (item.ticket_status == "Open") {
        return "pending";
      } else if (item.ticket_status == "Inprocess") {
        return "pickup";
      }
      if (item.ticket_status == "Close/Resolved") {
        return "completed";
      } else {
        return;
      }
    },
  },
  mounted() {
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    this.getTickets();
    if (this.edit) {
      this.editFlash = true;
    }
  },
  created() {
    this.subscription = commonService.getEvent().subscribe((eventName) => {
      if (eventName.text == "refreshTickets") {
        this.getTickets();
      }
    });
  },
  beforeDestroy() {
    this.subscription.unsubscribe();
  },
};
</script>
<style>
.pending {
  background-color: #ff000021;
}
.active {
  background-color: #fffe0026;
}
.pickup {
  background-color: #fffe0026;
}
.completed {
  background-color: #00800026;
}
</style>
