var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"ticket","fluid":"","tag":"section"}},[_c('v-snackbar',{attrs:{"timeout":4000,"top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.editFlash = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.editFlash),callback:function ($$v) {_vm.editFlash=$$v},expression:"editFlash"}},[_c('span',[_vm._v("Ticket Edit Successfully")])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.columns,"items":_vm.tickets,"item-key":"_id","hide-default-footer":"","disable-pagination":"","loading":_vm.loading,"loading-text":"Loading... Please wait","item-class":_vm.itemStatusColor},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mx-4 mt-4",attrs:{"label":"Search"},on:{"keyup":_vm.globalSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_name.length > 0 ? item.user_name[0].first_name : "")+" "+_vm._s(item.user_name.length > 0 ? item.user_name[0].last_name : "")+" ")]}},{key:"item.ticket_status",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.status},on:{"change":function($event){return _vm.updateStatus(item._id, item.ticket_status)}},model:{value:(item.ticket_status),callback:function ($$v) {_vm.$set(item, "ticket_status", $$v)},expression:"item.ticket_status"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.modulePermission.Update)?_c('router-link',{attrs:{"to":{
          name: 'Edit Ticket',
          params: { id: item._id },
        }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-eye")])],1):_vm._e()]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.created_at))+" ")]}},{key:"item.closed_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.closed_at))+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogUpdate),callback:function ($$v) {_vm.dialogUpdate=$$v},expression:"dialogUpdate"}},[_c('v-card',[_c('v-card-title',[_vm._v("Are you sure you want to update record?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","depressed":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary darken-1","depressed":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Update")]),_c('v-spacer')],1)],1)],1),_c('div',{staticClass:"custom_pagination_design"},[_c('span',[_vm._v(" Showing "),_c('b',[_vm._v(_vm._s(_vm.startRecord))]),_vm._v(" to "),_c('b',[_vm._v(_vm._s(_vm.endRecord))]),_vm._v(" of "),_c('b',[_vm._v(_vm._s(_vm.totalRecord))]),_vm._v(" Records ")]),_c('v-pagination',{staticClass:"float-right",attrs:{"length":_vm.totalPages,"total-visible":"7","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }